import ability from '@/libs/acl/ability'
import { $themeConfig } from '@themeConfig'
import imageToBase64 from 'image-to-base64/browser'

const util = {
  // return: errorText or ''
  validValue(label, value, condition) {
    if (condition === 'required') {
      return (value || value === 0) ? '' : `${label} là bắt buộc`
    }

    if (!value) return ''

    if (condition === 'email') {
      return /^\w+([/.-]?\w+)*@\w+([/.-]?\w+)*(\.\w{2,3})+$/.test(value) ? '' : `${label} không đúng định dạng`
    }

    if (condition === 'houseNumber') {
      return this.checkHouseNumber(value) ? '' : 'Số nhà không đúng định dạng'
    }

    if (condition === 'floorArea') {
      const nums = value.split(',').filter(item => item).map(item => item.trim()) || []
      for (let i = 0; i < nums.length; i += 1) {
        if (Number.isNaN(Number(nums[i]))) {
          return 'Diện tích sàn không hợp lệ'
        }
      }
      return ''
    }

    if (condition === 'phone') {
      return /(84|0[3|5|7|8|9|2])+([0-9]{8}|[0-9]{9})\b/g.test(value) ? '' : `${label} không đúng định dạng`
    }

    return ''
  },

  is3MinAgo(time) {
    if (!time) return false
    try {
      const n = new Date().getTime()
      const t = new Date(time).getTime()
      const v = 5 * 60 * 1000
      return (n - t >= v)
          || (new Date(time).getDate() < new Date().getDate())
    } catch (e) {
      console.log('Lỗi thời gian', e)
    }
    return false
  },

  getUrlFile(name = '', timeCreated, resolution = '') {
    let filename = name
    if (resolution) {
      const arr = name.split('.')
      const r = (arr[arr.length - 2] || '').split('_').pop()
      if (!Number.isNaN(r) && r.length > 2 && r.length < 5) {
        const a = (arr[arr.length - 2] || '').split('_')
        a[a.length - 1] = resolution
        arr[arr.length - 2] = a.join('_')
        filename = arr.join('.')
        // console.log(filename)
      } else {
        // console.log('n', r)
      }
    }
    if (!timeCreated || !this.is3MinAgo(timeCreated)) return `${$themeConfig.app.host}/upload?method=image&name=${filename}`
    return `${$themeConfig.app.s3_link}/${filename}`
  },

  getUrlImgProxy(name = '') {
    return `${$themeConfig.app.host}/upload?method=image&name=${name}`
  },

  async getUrlImgBase(name) {
    // const url = `${$themeConfig.app.host}/upload?method=image&name=${name?.replace('_1024', '_240') || ''}`
    const url = `${$themeConfig.app.host}/upload?method=image&name=${name || ''}`
    let base64Data = await imageToBase64(url)
    // nếu tải _240 thất bại thì thử tải _160
    if (!base64Data) {
      base64Data = await imageToBase64(url.replace('_240', '_160'))
    }
    return base64Data ? `data:image/png;base64,${base64Data}` : ''
  },

  logMe(...args) {
    console.log(...args)
  },

  parseFieldsToNumber(object = {}, fields = []) {
    const obj = { ...object }
    fields.forEach(field => {
      obj[field] = parseFloat(object[field] || '0')
    })
    return obj
  },

  deleteEmptyField(object) {
    if (!object || typeof object !== 'object') return object
    const obj = { ...object }
    Object.keys(obj).forEach(key => {
      if (obj[key] === undefined
          || obj[key] === null
          || obj[key] === '') {
        delete obj[key]
      }
    })
    return obj
  },

  deleteFields(object = {}, fields = []) {
    const obj = { ...object }
    fields.forEach(field => {
      delete obj[field]
    })
    return obj
  },

  formatDate(date, withTime = false) {
    if (!date) return ''
    try {
      const _ = new Date(date)
      let D = _.getDate()
      D = D < 10 ? `0${D}` : D
      let M = _.getMonth() + 1
      M = M < 10 ? `0${M}` : M
      let Y = _.getFullYear()
      Y = Y < 10 ? `0${Y}` : Y
      let h = _.getHours()
      h = h < 10 ? `0${h}` : h
      let m = _.getMinutes()
      m = m < 10 ? `0${m}` : m
      return `${D}/${M}/${Y}${withTime ? ` ${h}:${m}` : ''}`
    } catch (e) {
      return date
    }
  },

  formatMoney(val) {
    let num = val || 0
    let postfix = 'đ'
    if (num > 1e9) {
      num = Math.floor(num / 1e7) / 100
      postfix = 'tỷ'
    } else if (num > 1e6) {
      num = Math.floor(num / 1e4) / 100
      postfix = 'triệu'
    } else {
      num = (num / 1).toFixed(0)
        .replace('.', ',')
      num = num.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    return `${num} ${postfix}`
  },

  removeVietnameseTones(s) {
    let str = s || ''
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    str = str.replace(/đ/g, 'd')
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
    str = str.replace(/Đ/g, 'D')
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, '') // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, ' ')
    str = str.trim()
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'|"|&|#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ')
    return str
  },

  formatNumber(value = '', keepFraction = 3) {
    if (!value || value < 1 / 1000000) return '0'
    let res = value.toString()
    try {
      if (!Number.isNaN(res)) {
        let fractionNumber = res.split(this.NUMBER_LOCALE_FORMAT === 'vi-VN' ? ',' : '.')[1] || ''
        res = res.split(this.NUMBER_LOCALE_FORMAT === 'vi-VN' ? ',' : '.')[0] || ''
        res = res.replaceAll(this.NUMBER_LOCALE_FORMAT === 'vi-VN' ? '.' : ',', '')

        res = parseFloat(res) || 0

        fractionNumber = fractionNumber.substr(0, keepFraction)
        if (fractionNumber) {
          res = parseFloat(`${res}${this.NUMBER_LOCALE_FORMAT === 'vi-VN' ? ',' : '.'}${fractionNumber}`)
        }
        res = res.toLocaleString(this.NUMBER_LOCALE_FORMAT)
      }
    } catch (e) {
      console.log(`Exception error formatInputNumber: ${value}`)
    }
    return res || 0
  },

  getErrorForm(formId) {
    const $errors = document.querySelectorAll(`#${formId} .invalid-feedback`) || []
    return [...$errors].map($err => $err.innerHTML)
  },

  getHomeRouteForLoggedInUser() {
    if (ability().can('read', 'Overview')) return { name: 'overview' }
    if (ability().can('read', 'Notification')) return { name: 'notification-list' }
    if (ability().can('list', 'Report')) return { name: 'report-list' }
    return { name: 'auth-login' }
  },

  checkHouseNumber(houseNumber) {
    const str = houseNumber.trim() || ''

    const listNumber = str.match(/\d+/g) || []
    const listString = str.match(/[a-zA-Z]+/g) || []
    const listSlash = str.match(/\//g) || []
    const listHyphen = str.match(/-/g) || []
    const listSpace = str.split(' ')
    if (listSpace.length > 1) return false

    // type1: 12/1-12/3
    // type2: 12A-12B-12C
    // type3: 12-14
    // type4.1: 12Bis
    // type4.1: 12Ter
    // type4.2: 12A
    // type5: 12
    // type6: 12/12
    // type7: 242A-244 | 242-244A
    // type8: 12-14-16
    // type9: 12A1
    // type10: 12A1
    // type11: A91/A92
    // type12: A91
    // type13: 602/29C

    let isValid = true

    if (houseNumber.match(/^[A-Z]*[1-9]\d?(?:(?:[1-9]|[/-](\d|[A-Z])+([1-9]?|Bis)))*?$/)) {
      return isValid
    }

    if (listNumber.length === 2 && listString.length === 1 && listSlash.length === 0 && listHyphen.length === 0) {
      console.log('12A1')
      isValid = str.indexOf(listString[0]) > 0 && str.indexOf(listString[0]) < str.length && isValid
      return isValid
    }

    if (listNumber.length === 4 && listString.length === 0 && listSlash.length === 2 && listHyphen.length === 1) {
      console.log('12/1-12/3')
      const spl = str.split('-')
      isValid = Number.parseInt(listNumber[0], 10) === Number.parseInt(listNumber[2], 10) && Number.parseInt(listNumber[1], 10) < Number.parseInt(listNumber[3], 10) && isValid
      isValid = spl[0].indexOf('/') > -1 && spl[0].indexOf('/') < spl[0].length - 1 && isValid
      isValid = spl[1].indexOf('/') > -1 && spl[1].indexOf('/') < spl[1].length - 1 && isValid
      return isValid
    }

    if (listNumber.length === 2 && listString.length === 2 && listSlash.length === 0 && listHyphen.length === 1) {
      console.log('12A-12B')
      const spl = str.split('-')
      isValid = Number.parseInt(listNumber[0], 10) === Number.parseInt(listNumber[1], 10) && isValid
      isValid = listString[0].length < 2 && listString[1].length < 2 && isValid
      isValid = listString[0] !== listString[1] && isValid
      isValid = spl[0].indexOf(listNumber[0]) > -1 && spl[0].indexOf(listNumber[0]) < spl[0].indexOf(listString[0]) && isValid
      isValid = spl[1].indexOf(listNumber[1]) > -1 && spl[1].indexOf(listNumber[1]) < spl[1].indexOf(listString[1]) && isValid
      return isValid
    }

    if (listNumber.length > 1 && listString.length === 0 && listSlash.length === 0 && (listHyphen.length === listNumber.length - 1)) {
      console.log('12-14-...-100')
      const spl = str.split('-')
      for (let count = 0; count < listHyphen.length; count += 1) {
        isValid = Number.parseInt(spl[count], 10) < Number.parseInt(spl[count + 1], 10) && isValid
      }
      return isValid
    }

    if (listNumber.length === 1 && listString.length === 1 && listSlash.length === 0 && listHyphen.length === 0) {
      console.log('12Bis || KIOT32 || CC32 || 12Ter')
      if (str.indexOf(listNumber[0]) < str.indexOf(listString[0])) {
        isValid = listString[0][0] !== listString[0][0].toLowerCase() && isValid
        isValid = (listString[0].length === 1 || (listString[0] === 'Bis' || listString[0] === 'Ter')) && isValid
      }
      if (str.indexOf(listNumber[0]) > str.indexOf(listString[0])) {
        isValid = (listString[0] === 'KIOT' || listString[0] === 'CC' || listString[0] === 'A') && isValid
      }
      return isValid
    }

    if (listNumber.length === 1 && listString.length === 0 && listSlash.length === 0 && listHyphen.length === 0) {
      console.log('12')
      return isValid
    }

    if (listNumber.length === 3 && listString.length === 2 && listSlash.length === listNumber.length - 1 && listHyphen.length === 0) {
      console.log('8A/2/D1')
      return isValid
    }

    if (listNumber.length === 2 && listString.length === 2 && listSlash.length === 1 && listHyphen.length === 0) {
      console.log('A91/A92')
      return isValid
    }

    if (listNumber.length > 1 && listString.length === 0 && listSlash.length === listNumber.length - 1 && listHyphen.length === 0) {
      console.log('12/12/12/.../12')
      return isValid
    }

    if (houseNumber.match(/^[1-9]\d*[A-Z]?(?:(?:[A-Z]|[/-]\d+([A-Z]?|Bis)))*?$/)) {
      return isValid
    }

    if (houseNumber.match(/^[A-Z]*[1-9]\d?[A-Z]*$/)) {
      return isValid
    }

    if (listNumber.length === 2 && listString.length === 1 && listSlash.length === 0 && listHyphen.length === 1) {
      console.log('242A-244 | 242-242A | 242-244A')
      const spl = str.split('-')
      isValid = Number.parseInt(listNumber[0], 10) <= Number.parseInt(listNumber[1], 10) && isValid
      isValid = (spl[0].indexOf(listNumber[0]) !== -1) && isValid
      isValid = (spl[1].indexOf(listNumber[1]) !== -1) && isValid
      isValid = (spl[0].indexOf(listString[0]) === -1 || spl[0].indexOf(listString[0]) > spl[0].indexOf(listNumber[0])) && isValid
      isValid = (spl[1].indexOf(listString[0]) === -1 || spl[1].indexOf(listString[0]) > spl[1].indexOf(listNumber[1])) && isValid
      console.log(spl[0].indexOf(listString[0]), spl[0].indexOf(listNumber[0]), spl[1].indexOf(listNumber[1]), spl[1].indexOf(listString[0]))
      return isValid
    }

    return false
  },
}
export default util
