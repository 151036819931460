const constant = {}
const years = []
const nextYears = []
for (let i = new Date().getFullYear() - 10; i <= new Date().getFullYear(); i += 1) {
  years.unshift(i)
}
for (let i = new Date().getFullYear(); i <= new Date().getFullYear() + 10; i += 1) {
  nextYears.unshift(i)
}

constant.ROLES = [
  {
    value: 'admin',
    text: 'Admin Tổng',
    id: 1,
    disabled: true,
  },
  {
    value: 'supporter',
    text: 'Admin',
    id: 5,
    validBy: ['show'],
  },
  {
    value: 'business_manager',
    text: 'GĐ Kinh Doanh',
    id: 6,
  },
  {
    value: 'personnel_administration',
    text: 'Hành Chính Nhân Sự',
    id: 7,
  },
  {
    value: 'manager',
    text: 'Quản lý',
    id: 2,
    validBy: ['show'],
  },
  {
    value: 'manager_branch',
    text: 'Quản lý CN',
    id: 4,
    validBy: ['show'],
  },
  {
    value: 'user',
    text: 'Nhân viên',
    id: 3,
    validBy: ['show'],
  },
]
constant.OFFICE_DIRECTION = [
  {
    value: 'dong',
    text: 'Đông',
    textEN: 'East',
  },
  {
    value: 'tay',
    text: 'Tây',
    textEN: 'Ưest',
  },
  {
    value: 'nam',
    text: 'Nam',
    textEN: 'South',
  },
  {
    value: 'bac',
    text: 'Bắc',
    textEN: 'North',
  },
  {
    value: 'dong-bac',
    text: 'Đông Bắc',
    textEN: 'NorthEast',
  },
  {
    value: 'dong-nam',
    text: 'Đông Nam',
    textEN: 'SouthEast',
  },
  {
    value: 'tay-bac',
    text: 'Tây Bắc',
    textEN: 'NorthWest',
  },
  {
    value: 'tay-nam',
    text: 'Tây Nam',
    textEN: 'SouthWest',
  },
]
constant.OFFICE_RANK = [
  {
    value: 'a',
    text: 'A',
  },
  {
    value: 'b',
    text: 'B',
  },
  {
    value: 'c',
    text: 'C',
  },
  {
    value: 'd',
    text: 'D',
  },
  {
    value: 'e',
    text: 'E',
  },
]
constant.OVERTIME_FEE_TYPE = [
  {
    value: 'thoa-thuan',
    text: 'Thỏa thuận',
  },
  {
    value: 'm2-hour',
    text: 'VNĐ/m2/giờ',
  },
  {
    value: 'hour',
    text: 'VNĐ/giờ',
  },
]
constant.ELECTRICITY_FEE_TYPE = [
  {
    value: 'phi-nha-nuoc',
    text: 'Phí nhà nước',
  },
  {
    value: 'kWh',
    text: 'VNĐ/kWh',
  },
]
constant.IS_NOT_EXPORTED = [
  {
    value: '',
    text: 'Tất cả',
  },
  {
    value: true,
    text: 'Chưa xuất',
  },
]
constant.NEXT_YEAR_OPTIONS = nextYears
constant.YEAR_OPTIONS = years
constant.MONTH_OPTIONS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
constant.ACTIONS_BUTTON = {
  CREATE: {
    label: 'Tạo mới',
    icon: 'FileTextIcon',
    value: 'create',
  },
  EDIT: {
    label: 'Chỉnh sửa',
    icon: 'EditIcon',
    value: 'edit',
    suggest: true,
  },
  UPDATE: {
    label: 'Cập nhật',
    icon: 'SaveIcon',
    value: 'save',
    suggest: true,
  },
  REFRESH: {
    label: 'Làm mới',
    icon: 'RefreshCcwIcon',
    value: 'refresh',
  },
  CLOSE: {
    label: 'Close deal',
    icon: 'CheckSquareIcon',
    value: 'close',
  },
  OPEN: {
    label: 'Mở giao dịch',
    icon: 'BookOpenIcon',
    value: 'open',
  },
  EXPORT_EXCEL: {
    label: 'Xuất excel',
    icon: 'FileTextIcon',
    value: 'excel',
  },
  CREATE_CAMPAIGN: {
    label: 'Tạo chiến dịch',
    icon: 'CrosshairIcon',
    value: 'create-campaign',
  },
}
constant.HOUSE_STYLE = [
  {
    text: 'Sân thượng',
    value: 'terrace',
  },
  {
    text: 'Hầm',
    value: 'basement',
  },
  {
    text: 'Lửng',
    value: 'badger',
  },
  {
    text: 'Thang máy',
    value: 'elevator',
  },
]
constant.SPECIAL = [
  {
    text: 'Sản phẩm hot',
    value: 'is_hot',
  },
  {
    text: 'Có góc',
    value: 'has_corner',
  },
  {
    text: 'Hiện thị trên website',
    value: 'show_in_web',
  },
]
constant.FORMALITY_PRODUCT = [
  {
    text: 'Cho thuê',
    value: 'cho-thue',
    validBy: ['cho-thue'],
  },
  {
    text: 'Bán',
    value: 'ban',
    validBy: ['ban'],
    invalidByProductFilter: true,
  },
]
constant.INPUT_TYPE = [
  'text',
  'number',
  'email',
  'password',
  'search',
  'url',
  'tel',
  'date',
  'time',
  'range',
  'color',
]
constant.TYPE_PRODUCT = [
  {
    text: 'Tất cả',
    value: 'all',
    validBy: ['thue', 'mua'],
  },
  {
    text: 'Nhà mặt tiền',
    value: 'nha-mat-tien',
    validBy: ['thue', 'mua', 'cho-thue', 'ban'],
  },
  {
    text: 'Nhà hẻm',
    value: 'nha-hem',
    validBy: ['thue', 'mua', 'cho-thue', 'ban'],
  },
  {
    text: 'Khách sạn, CHDV',
    value: 'khach-san',
    // validBy: ['thue', 'mua', 'cho-thue', 'ban'],
    validBy: [],
  },
  {
    text: 'Khách sạn, CHDV',
    value: 'khach-san',
    validBy: ['ban'],
  },
  {
    text: 'Kho xưởng',
    value: 'warehouse',
    validBy: ['thue', 'cho-thue'],
  },
  {
    text: 'Văn phòng',
    value: 'van-phong',
    validBy: [],
  },
  {
    text: 'ShopHouse',
    value: 'shop-house',
    validBy: [],
  },
  {
    text: 'Chung cư',
    value: 'chung-cu',
    validBy: [],
  },
  {
    text: 'Sân thượng',
    value: 'san-thuong',
    validBy: ['thue', 'cho-thue'],
  },
]
constant.FORMALITY_CUSTOMER = [
  {
    text: 'Thuê',
    value: 'thue',
  },
  {
    text: 'Mua',
    value: 'mua',
  },
]
constant.PROVINCE = [
  {
    id: 1,
    text: 'Hồ Chí Minh',
    value: 'HCM',
  },
  {
    id: 5,
    text: 'Đồng Nai',
    value: 'ĐN',
  },
  {
    id: 4,
    text: 'Bình Dương',
    value: 'BD',
  },
  {
    id: 10,
    text: 'Bà Rịa Vũng Tàu',
    value: 'VT',
  },
]
constant.CURRENCY = [
  {
    text: 'Triệu',
    value: 'million',
    validBy: ['ban', 'mua', 'vnd', 'million'],
  },
  {
    text: 'Tỉ',
    value: 'billion',
    validBy: ['ban', 'mua', 'vnd', 'billion'],
  },
  {
    text: 'USD',
    value: 'usd',
    validBy: ['cho-thue', 'thue', 'usd'],
  },
]
constant.TYPE_OF_PAYMENT = [
  {
    text: 'Tháng',
    value: 'month',
    validBy: ['cho-thue', 'thue'],
  },
  {
    text: 'Tổng',
    value: 'total',
    validBy: ['ban', 'mua'],
  },
  {
    text: 'm2',
    value: 'm2',
    validBy: ['ban', 'mua'],
  },
]
constant.EXPAND_STYLE = [
  {
    text: 'Không',
    value: '',
  },
  {
    text: 'Top hậu',
    value: 'top-behind',
  },
  {
    text: 'Nở hậu',
    value: 'behind-expand',
  },
]
constant.COMMISSION = [
  {
    text: '1 tháng',
    value: 'month',
  },
  {
    text: 'Nửa tháng',
    value: 'half-a-month',
  },
  {
    text: '1%',
    value: '1%',
  },
  {
    text: 'TL',
    value: 'TL',
  },
]

constant.COMMISSION_OFFICE = [
  {
    text: '1 tháng',
    value: 'month',
  },
  {
    text: 'Nửa tháng',
    value: 'half-a-month',
  },
  {
    text: '1%',
    value: '1%',
  },
  {
    text: 'TL',
    value: 'TL',
  },
  {
    text: 'Khác',
    value: 'other',
  },
]

constant.CAMPAIGN_STATUS = [
  {
    value: 'new',
    colorName: 'secondary',
    text: 'Chưa xác nhận',
  },
  {
    value: 'available',
    text: 'Còn cho thuê',
  },
  {
    value: 'available_send_another_product',
    text: 'Còn cho thuê - gửi căn khác',
  },
  {
    value: 'unavailable',
    text: 'Đã cho thuê',
  },
  {
    value: 'unavailable_send_another_product',
    text: 'Đã cho thuê - gửi căn khác',
  },
  {
    value: 'dont_pick_up',
    text: 'Không bắt máy',
  },
  {
    value: 'not_the_owner',
    text: 'Chủ củ - không phải chủ',
  },
  {
    value: 'agency',
    text: 'Môi giới',
  },
  {
    value: 'cannot_checked',
    text: 'Chưa check được',
  },
  // {
  //   value: 'send_another_product',
  //   text: 'Gửi căn khác',
  // },
  // {
  //   value: 'cannot_be_contacted',
  //   text: 'Chưa liên hệ được',
  // },
]

constant.CAMPAIGN_CONFIRM_STATUS = [
  {
    value: 'new',
    text: 'Mới',
  },
  {
    value: 'available',
    text: 'Còn cho thuê',
  },
  {
    value: 'available_send_another_product',
    text: 'Còn cho thuê - gửi căn khác',
  },
  {
    value: 'unavailable',
    text: 'Đã cho thuê',
  },
  {
    value: 'unavailable_send_another_product',
    text: 'Đã cho thuê - gửi căn khác',
  },
  {
    value: 'dont_pick_up',
    text: 'Không bắt máy',
  },
  {
    value: 'not_the_owner',
    text: 'Chủ củ - không phải chủ',
  },
  {
    value: 'agency',
    text: 'Môi giới',
  },
  {
    value: 'cannot_checked',
    text: 'Chưa check được',
  },
]

export default constant
