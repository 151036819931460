// const host = process.env.NODE_ENV === 'production' ? 'https://cart.VPCC.vn' : 'https://VPCC.doancuong.com'
const host = (window.location.host.includes('doancuong')
|| window.location.host.includes('local')) ? 'https://connectland.doancuong.com' : 'https://cart.VPCC.vn'

// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    project_name: 'VPCC',
    env_name: 'kimquang',
    website_name: 'Công Ty VPCC',
    admin_name: '',
    email: 'VPCC@gmail.com',
    hotline: '0987686868',
    showConfigCustom: false,
    // s3_link: 'https://VPCC.doancuong.com/image',
    s3_link: 'https://VPCC.s3.ap-southeast-1.amazonaws.com',
    proxy_image: 'https://VPCC.doancuong.com/image',
    thumbSize: '',
    download_image: true,
    appName: 'Công Ty VPCC', // Will update name in navigation menu (Branding)
    website: '',
    // eslint-disable-next-line global-require
    appLogoImage: require('@/assets/images/logo/logo-text.png'), // Will update logo in navigation menu (Branding)
    // eslint-disable-next-line global-require
    loginBackground: require('@/assets/images/pages/login-v2.svg'),
    host,
    urlUpload: `${host}/upload`,
    textUnauthorized: 'Phiên đăng nhập hết hạn, hệ thống sẽ đăng xuất!',
  },
  layout: {
    isRTL: false,
    skin: 'semi-dark', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'sticky', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static', // static, sticky, hidden
    },
    customizer: false,
    enableScrollToTop: true,
  },
}
