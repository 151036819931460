import { Ability, AbilityBuilder } from '@casl/ability'

const ability = () => {
  let jsonUserData = localStorage.getItem('userData') || '{}'
  jsonUserData = jsonUserData === 'undefined' ? '{}' : jsonUserData
  const userData = JSON.parse(jsonUserData) || {}
  const uRole = userData.role || ''

  const {
    can,
    cannot,
    rules,
  } = new AbilityBuilder(Ability)

  const uCan = []
  const uCant = []

  uCan.push(['manage', 'Auth'])
  // hành chính nhân sự sẽ ko có toàn quyền công dân, nó chỉ đc xem báo cáo
  if (uRole !== 'personnel_administration') {
    uCan.push(['manage', 'Notification'])
    uCan.push(['manage', 'Account'])
  }

  // ADMIN - admin
  if (uRole === 'admin' || uRole === 'business_manager') {
    uCan.push(['manage', 'all'])
    if (uRole === 'business_manager') {
      uCant.push(['export-excel', 'all'])
    }
  } else {
    uCan.push(['read', ['UserTitle']])
    if (userData.is_access_townhouse_hire || userData.is_access_townhouse_sell) {
      uCan.push(['read', ['ProductCornerTitle', 'ProductHotelTitle']])
    } else {
      // ẩn menu khách nhà phố
      uCant.push([['read', 'list', 'create'], ['CustomerCorner', 'CustomerCornerManage']])
    }
    // ẩn menu thuê
    if (!userData.is_access_townhouse_hire) {
      uCant.push([['read', 'list', 'create'], ['ProductHired', 'ProductHiredManage', 'ProductExpire']])
    }
    // ẩn menu bán
    if (!userData.is_access_townhouse_sell) {
      uCant.push([['read', 'list', 'create'], ['ProductSell', 'ProductSellManage']])
    }
    // ẩn menu hotel
    if (!userData.is_access_hotel) {
      uCant.push([['read', 'list', 'create'], ['ProductHotelTitle', 'ProductHotel', 'ProductHotelManage', 'CustomerHotel', 'CustomerHotelManage']])
    }
    if (userData.is_access_office) {
      uCan.push(['manage', ['OfficeTitle', 'CustomerOffice', 'CustomerOfficeManage', 'ProductOffice', 'ProductOfficeManage']])
    }
  }

  // SUPPORT ADMIN - supporter
  if (uRole === 'supporter') {
    uCan.push(['read', 'CheckProduct'])
    uCan.push([['list', 'detail', 'read', 'edit'], ['Campaign']])
    uCan.push([['list', 'detail', 'create'], ['ProductHired', 'ProductSell', 'ProductExpire', 'ProductHotel']])
    uCan.push([['edit', 'delete'], ['ProductHired', 'ProductSell', 'ProductHotel'], {
      is_can_edit: {
        $eq: true,
      },
    }])
    // hiện Nhập NP <100tr >100tr ở báo cáo chi tiết nhân viên
    uCan.push(['show-total_sum_user_office_price', 'Report'])
  }

  // HÀNH CHÍNH NHÂN SỰ - personnel_administration
  if (uRole === 'personnel_administration') {
    uCant.push([['read'], ['ProductHotelTitle', 'ProductCornerTitle', 'UserTitle']])
    uCan.push([['read', 'list', 'detail'], ['Report', 'ReportTitle']])
    uCan.push([['read', 'detail'], ['User']])
  }

  // MANAGER BRANCH - manager_branch
  if (uRole === 'manager_branch') {
    // quản lý chi nhánh có quyền thiết lập giới hạn xem sản phẩm
    uCan.push([['update'], ['UserLimit']])
    uCan.push([['manage'], ['Report', 'ReportTitle']])
    uCan.push([['detail'], ['User']])
    uCan.push([['list', 'detail', 'create'], ['CustomerCorner', 'CustomerHotel']])
    uCan.push(['read', ['CustomerCornerManage', 'CustomerHotelManage']])
    uCan.push([['list', 'detail'], ['ProductHired', 'ProductSell', 'ProductHotel']])
    uCan.push([['edit', 'delete'], ['CustomerCorner', 'CustomerHotel'], {
      user_id: {
        $eq: userData.id,
      },
    }])
  }

  // MANAGER - manager
  if (uRole === 'manager') {
    uCan.push(['manage', 'all'])
    uCan.push(['read', 'CheckProduct'])
    uCant.push([['edit', 'delete'], ['ProductHired', 'ProductSell', 'CustomerCorner', 'CustomerOffice', 'ProductHotel', 'CustomerHotel'], {
      is_can_edit: {
        $eq: false,
      },
    }])
    uCant.push([['edit', 'delete'], 'User'])
    uCant.push(['create', 'User'])
  }

  // USER - user
  if (uRole === 'user') {
    uCan.push([['list', 'detail'], ['ProductHired', 'ProductSell', 'ProductHotel']])
    uCan.push([['create', 'detail'], ['CustomerCorner', 'CustomerHotel']])
    uCan.push(['read', ['CustomerCornerManage', 'CustomerHotelManage']])
    uCan.push([['edit'], ['CustomerCorner', 'CustomerHotel'], {
      user_id: {
        $eq: userData.id,
      },
    }])
  }

  //
  uCan.filter(a => a[0] && a[1])
    .forEach(a => can(...a))
  uCant.filter(a => a[0] && a[1])
    .forEach(a => cannot(...a))

  return new Ability(rules)
}

export default ability
