import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getUserData} from '@/auth/utils'
import ability from '@/libs/acl/ability'
import useJwt from '@/auth/jwt/useJwt'
import {subject} from '@casl/ability'
import axiosIns from '@/libs/axios'
import constant from '@/global/const'
import util from '@/global/util'

const fakeAttributes = [
  {
    name: "Tài sản cố định",
    id: 6291,
    value: "tai-san-co-đinh",
    list: [
      {
        name: "Vị trí tài sản",
        id: 7134,
        value: "đat-đai",
        require: false,
        type: "text"
      },
      {
        name: "Giá trị ước lượng",
        id: 2307,
        value: "tai-san-vat-chat",
        require: false,
        type: "text"
      },
      {
        name: "Ảnh tài sản",
        id: 2301,
        value: "tai-san-vat-chatasf",
        require: false,
        type: "image"
      },
      {
        name: "Tài liệu",
        id: 2301,
        value: "tai-san-vat-chatasf",
        require: false,
        type: "file"
      },
      {
        name: "Mô tả chủ sở hữu",
        id: 3502,
        value: "tai-san-phi-vat-chat",
        require: false,
        type: "text"
      }
    ]
  },
  {
    name: "Tài sản lưu động",
    id: 8994,
    value: "tai-san-luu-đong",
    list: [
      {
        name: "Tên tài sản",
        id: 7919,
        value: "tien-va-tuong-đuong-tien",
        require: false,
        type: "text"
      },
      {
        name: "Giá trị ước lượng",
        id: 2486,
        value: "chung-khoan",
        require: false,
        type: "text"
      },
      {
        name: "Hình thức",
        id: 3552,
        value: "tai-fsan-phi-vat-chat",
        require: false,
        type: "text"
      }
    ]
  },
  {
    name: "Tài sản cá nhân",
    id: 3595,
    value: "tai-san-ca-nhan",
    list: [
      {
        name: "Tên tài sản",
        id: 6968,
        value: "đo-đac-ca-nhan",
        require: false,
        type: "text"
      },
      {
        name: "Giá trị ước lượng",
        id: 2858,
        value: "xe-o-to",
        require: false,
        type: "text"
      },
      {
        name: "Giấy tờ",
        id: 3572,
        value: "tai-fsagn-phi-vat-chat",
        require: false,
        type: "text"
      }
    ]
  },
  {
    name: "Tài sản thực thể",
    id: 3466,
    value: "tai-san-thuc-the",
    list: [
      {
        name: "Tên tài sản",
        id: 3147,
        value: "bat-đong-san",
        require: false,
        type: "text"
      },
      {
        name: "Giá trị ước lượng",
        id: 9262,
        value: "tai-san-co-đinh",
        require: false,
        type: "text"
      }
    ]
  },
  {
    name: "Tài sản có hình thức tiền mặt",
    id: 9908,
    value: "tai-san-co-hinh-thuc-tien-mat",
    list: [
      {
        name: "Tên tài sản",
        id: 4435,
        value: "tien-mat",
        require: false,
        type: "text"
      },
      {
        name: "Giá trị ước lượng",
        id: 3325,
        value: "tien-gui-ngan-hang",
        require: false,
        type: "text"
      },
    ]
  },
  {
    name: "Tài sản không có hình thức tiền mặt",
    id: 3092,
    value: "tai-san-khong-co-hinh-thuc-tien-mat",
    list: [
      {
        name: "Tên tài sản",
        id: 597,
        value: "bat-đong-san",
        require: false,
        type: "text"
      },
      {
        name: "Giá trị ước lượng",
        id: 8824,
        value: "so-huu-tri-tue",
        require: false,
        type: "text"
      }
    ]
  }
]
// GLOBAL DEFINE CONSTANT
Object.keys(constant).forEach(k => {
  Vue.prototype[k] = constant[k]
})

// GLOBAL HELPER FUNCTIONS
Object.keys(util).forEach(k => {
  Vue.prototype[k] = util[k]
})

// Const
const customerCategoryObj = {
  personal: {
    value: 'personal',
    text: 'Cá nhân',
  },
  company: {
    value: 'company',
    text: 'Doanh nghiệp',
  },
}
const contractApproving = {
  new: {
    value: 'new',
    text: 'Mới',
  },
  agree: {
    value: 'agree',
    text: 'Đồng ý',
  },
  deny: {
    value: 'deny',
    text: 'Không đồng ý',
  },
}
const userJobTitleOptions = [
  {
    value: 'TDPC',
    text: 'Trưởng Đội Pháp Chế',
    zIndex: 10,
  },
  {
    value: 'PDPC',
    text: 'Phó Đội Pháp Chế',
    zIndex: 6,
  },
  {
    value: 'DPC',
    text: 'Chuyên Viên Pháp Chế',
    zIndex: 2,
  },
]
const templateTypeOptions = [
  {text: 'Hợp đồng/Phụ lục', value: 'form'},
  {text: 'Phiếu yêu cầu', value: 'ticket'},
]

const statusObj = {
  entry_new: {
    value: 'entry_new',
    text: 'Hồ sơ mới tiếp nhận',
    icon: 'FileTextIcon',
    color: '#28c76f',
    actionBeforetext: 'Tạo hồ sơ',
    next: ['verify_new'],
    type: 'hs',
    abilityNeeded: 'assign',
  },
  verify_new: {
    value: 'verify_new',
    text: 'Hồ sơ đang xác minh',
    icon: 'UsersIcon',
    color: '#ff993c',
    actionBeforetext: 'Chuyển đội - Xác minh',
    next: ['verify_approving'],
    type: 'hs',
    abilityNeeded: 'verification',
  },
  verify_approving: {
    value: 'verify_approving',
    text: 'Đang chờ phê duyệt',
    color: '#756bc9',
    icon: 'InfoIcon',
    actionBeforetext: 'Xác minh',
    next: ['verify_approving'],
    type: 'hs',
    abilityNeeded: 'verification',
  },
  contract_open: {
    value: 'contract_open',
    text: 'Hợp đồng mở',
    color: '#9dc213',
    icon: 'RotateCcwIcon',
    actionBeforetext: 'Hợp đồng mở',
    next: [],
    type: 'hd',
    abilityNeeded: 'browsing',
  },
  offer_to_sign: {
    value: 'offer_to_sign',
    text: 'Đề nghị ký HĐ',
    color: '#00cfe8',
    icon: 'CheckIcon',
    actionBeforetext: 'Duyệt',
    next: ['contract_signed', 'contract_denied'],
    type: 'hs',
    abilityNeeded: 'browsing',
  },
  verify_deny: {
    value: 'verify_deny',
    text: 'Trả hồ sơ',
    color: '#fdba85',
    icon: 'XIcon',
    actionBeforetext: 'Từ chối duyệt',
    next: ['verify_new'],
    type: 'hs',
    abilityNeeded: 'browsing',
  },
  contract_signed: {
    value: 'contract_signed',
    text: 'Hợp đồng đã ký',
    color: '#ea5455',
    icon: 'PenToolIcon',
    actionBeforetext: 'Ký hợp đồng',
    next: ['liquidity_processing', 'contract_open'],
    type: 'hd',
    abilityNeeded: 'liquidating',
  },
  contract_denied: {
    value: 'contract_denied',
    text: 'KH không đồng ý ký',
    color: '#4c42a6',
    icon: 'SlashIcon',
    actionBeforetext: 'KH không ký',
    next: ['liquidity_processing'],
    type: 'hd',
    abilityNeeded: 'liquidating',
  },
  liquidity_processing: {
    value: 'liquidity_processing',
    text: 'HĐ chờ thanh lý',
    color: '#b598be',
    icon: 'PauseIcon',
    actionBeforetext: 'Thanh lý',
    next: ['liquidity_completed'],
    type: 'hd',
    abilityNeeded: 'liquidating',
  },
  liquidity_completed: {
    value: 'liquidity_completed',
    text: 'Thanh lý HĐ',
    color: '#297c20',
    icon: 'CoffeeIcon',
    actionBeforetext: 'Đã Thanh lý',
    next: [],
    type: 'hd',
  },
}

const genderObj = {
  male: {
    text: 'Anh',
    value: 'male',
  },
  female: {
    text: 'Chị',
    value: 'female',
  },
}

const optionsGender = {
  male: {
    value: 'male',
    text: 'Ông',
  },
  female: {
    value: 'female',
    text: 'Bà',
  },
}

const gender = {
  get: k => genderObj[k],
  getName: k => genderObj[k]?.label || k,
  genderObj,
  list: Object.values(genderObj),
}

const optionsGenderOjb = {
  get: k => optionsGender[k],
  getName: k => optionsGender[k]?.label || k,
  optionsGender,
  list: Object.values(optionsGender),
}

const status = {
  get: k => statusObj[k],
  getName: k => statusObj[k]?.label || k,
  getColor: k => statusObj[k]?.color || k,
  statusObj,
  list: Object.values(statusObj),
}
const customerCategory = {
  get: k => customerCategoryObj[k],
  getName: k => customerCategoryObj[k]?.label || k,
  customerCategoryObj,
  list: Object.values(customerCategoryObj),
}

const years = []
const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
const monthsWithYear = []
let currentMonth = new Date().getMonth() + 1
let currentYear = new Date().getFullYear()

for (let i = 0; i < 12; i += 1) {
  monthsWithYear.unshift({
    name: `${currentMonth > 9 ? currentMonth : `0${currentMonth}`}/${currentYear}`,
    value: `${currentYear}/${currentMonth}`
  })
  currentMonth -= 1
  if (currentMonth === 0) {
    currentMonth = 12
    currentYear -= 1
  }
}
for (let i = 2010; i <= new Date().getFullYear(); i += 1) {
  years.unshift(i)
}

Vue.prototype.$optionsGenderOjb = optionsGenderOjb
Vue.prototype.$yearOptions = years
Vue.prototype.$monthOptions = months
Vue.prototype.$monthWithYearOptions = monthsWithYear
Vue.prototype.$userData = getUserData()
Vue.prototype.$status = status
Vue.prototype.$gender = gender
Vue.prototype.$customerCategory = customerCategory
Vue.prototype.$contractApproving = contractApproving
Vue.prototype.$userJobTitleOptions = userJobTitleOptions
Vue.prototype.$templateTypeOptions = templateTypeOptions

Vue.mixin({
  data() {
    return {
      attributes: [...fakeAttributes],
      attribute: {},
      attributeChild: {},
      documents: [],
      document: {},
      formatOptions: {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralPositiveOnly: true,
          numericOnly: true,
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      },
      NUMBER_LOCALE_FORMAT: undefined,
      $status: status,
      $customerCategory: customerCategory,
      NO_UPDATE_TEXT: '(Chưa cập nhật)',
    }
  },
  methods: {
    setLocalStorage(key, data) {
      return localStorage.setItem(key, JSON.stringify(data))
    },
    getLocalStorage(key) {
      return JSON.parse(localStorage.getItem(key))
    },
    getTemplateShortName(name = '') {
      const arr = name.split('.')
      arr.pop()
      const str = arr.join('.')
      return `...${str.length < 25 ? str : str.substr(str.length - 25)}`
    },
    downloadUrl(url, fileType = 'pdf', label) {
      const type = {
        pdf: 'application/pdf',
        excel: 'application/vnd.ms-excel',
      }[fileType]

      // url = 'https://source.unsplash.com/user/c_v_r/100x100'
      axiosIns.get(url, {
        responseType: 'blob',
        headers: {
          // Origin: 'http://localhost:8080',
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then(response => {
          const blob = new Blob([response.data], {
            type,
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label || `download-${new Date().getTime()}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    downloadImg(filename, label) {
      const url = util.getUrlImgProxy(filename)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', label)
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    openLinkInNewTab(url) {
      if (!url) return
      window.open(url, '_blank')
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('bookmarksInSession')

      // Reset ability
      // ability(initialAbility)

      // Redirect to login page
      this.$router.push({name: 'auth-login'})
    },
    getISODate(d) {
      try {
        const r = new Date(d)
        return r.toISOString()
      } catch (e) {
        return d
      }
    },
    hideModal(ref) {
      setTimeout(() => {
        this.$bvModal.hide(ref)
      })
    },
    showModal(id) {
      this.$bvModal.show(id)
    },
    nextStatus(contract) {
      const c = contract || {}
      const s = statusObj[c.status] || {}
      const n = s.next
      const an = s.abilityNeeded

      if (!n || !ability().can(an, subject('Contract', c))) return []
      return n.map(key => ({
        ...statusObj[key],
        text: statusObj[key].actionBeforeLabel,
      }))
    },
    $toastr(msg, variant = 'success', option = {}) {
      const eMsg = msg.response?.data?.message || msg.response?.data?.error?.message || msg
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: this.getTitleNotiByVariant(variant),
          text: eMsg || msg?.message || 'Thông báo',
          variant,
          icon: this.getIconNotiByVariant(variant),
          ...option,
        },
      })
    },
    getTime(date) {
      const time = util.formatDate(date, true)
      if (!time) return ''
      const a = time.split(' ')[1].split(':')
      return `${a[0]}h ${a[1]}p`
    },
    $showNoti(title = 'Thông báo', type = 'success', option = {}) {
      if (!title) return
      const eMsg = (title.response && title.response.data && title.response.data.title && title.response.data.title.message) || title.message
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: eMsg || title,
          variant: type,
          icon: '',
          ...option,
        },
      })
    },
    $goback(route) {
      return route ? this.$router.push(route) : this.$router.go(-1)
    },
    $formatMinMax(value, min = 0, max = 100) {
      let res = value
      if (res < min) res = min
      if (res > max) res = max
      return res
    },
    $call(promiseAction, showNoti = false) {
      return promiseAction
        .then(res => {
          if (showNoti) {
            this.$showNoti(typeof res === 'string' ? res : (res && res.message) || 'Thành công')
          }
          return res && res.data
        })
        .catch(err => {
          let eMsg = err?.response?.data?.message
          eMsg = eMsg || err?.response?.data?.error?.message || err.message || err
          this.$toastr(eMsg, 'danger')
          throw eMsg
        })
    },
    getTitleNotiByVariant(variant) {
      switch (variant) {
        case 'success':
        case 'primary':
          return 'Thành công'
        case 'warning':
          return 'Cảnh báo'
        case 'danger':
        case 'error':
          return 'Lỗi xảy ra'
        default:
          return 'Thông báo'
      }
    },
    getIconNotiByVariant(variant) {
      switch (variant) {
        case 'success':
        case 'primary':
          return 'CheckIcon'
        case 'warning':
          return 'AlertTriangleIcon'
        case 'danger':
        case 'error':
          return 'AlertTriangleIcon'
        default:
          return 'CoffeeIcon'
      }
    },
    $getItem(key) {
      try {
        let jsonData = localStorage.getItem(key)
        jsonData = jsonData === 'undefined' ? '""' : jsonData
        return JSON.parse(jsonData)
      } catch (e) {
        return ''
      }
    },
  },
})
