export default [
  {
    path: '/report-list',
    name: 'report-list',
    component: () => import('@/views/report/list/ReportList.vue'),
    meta: {
      action: 'list',
      resource: 'Report',
      breadcrumb: [
        {
          text: 'Báo cáo nhân viên',
        },
      ],
    },
  },
]
