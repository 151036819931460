export default [
  {
    path: '/document/list',
    name: 'document-list',
    component: () => import('@/views/document/list/ListDocument.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Hồ sơ',
        },
        {
          text: 'Tìm kiếm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/document/create',
    name: 'document-create',
    component: () => import('@/views/document/create/CreateDocument.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Hồ sơ',
          route: 'document-list',
        },
        {
          text: 'Tạo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/document/attribute',
    name: 'document-attribute',
    component: () => import('@/views/document/attribute/CreateDocument.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Thuộc tính',
          active: true,
        },
      ],
    },
  },
  {
    path: '/document/:id/edit',
    name: 'document-edit',
    component: () => import('@/views/document/edit/EditDocument.vue'),
    meta: {
      action: 'edit',
      resource: 'Document',
      breadcrumb: [
        {
          text: 'Hồ sơ',
          route: 'document-list',
        },
        {
          text: 'Chỉnh sửa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/document/:id/detail',
    name: 'document-detail',
    component: () => import('@/views/document/detail/DetailDocument.vue'),
    meta: {
      action: 'detail',
      resource: 'Document',
      breadcrumb: [
        {
          text: 'Hồ sơ',
          route: 'document-list',
        },
        {
          text: 'Chi tiết',
          active: true,
        },
      ],
    },
  },
]
